import React from "react"

import { ToastContainer, toast } from 'react-toastify';

import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/DataScienceAndMLCompany/MainBanner"
import OurSolutions from "../components/DataScienceAndMLCompany/OurSolutions"
import StartProject from "../components/DataScienceAndMLCompany/StartProject"
import HowItWork from '../components/DataScienceAndMLCompany/HowItWork';

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <OurSolutions />
      {/* <AboutUs /> */}
      {/* <OurServices /> */}
      <HowItWork />
      {/* <CaseStudySlider /> */}
      {/* <TeamMembers /> */}
      {/* <Testimonials /> */}
      {/* <Partner /> */}
      {/* <OurBlog /> */}
      <StartProject />
      <Footer />
      <ToastContainer />
    </Layout>
  )
}

export default Home