import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowitsWork from '../../assets/images/how-its-work.png'

const HowItWork = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image" /> 
                                How It Works
                            </span>
                            <h2>Getting Started with Martone</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Talk to One of Our Industry Experts</h3>
                                    <p>A Project development expert on our team will work with you to understand your goals, technical needs, and team dynamics.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Review Hand-Picked Talent</h3>
                                    <p>Within days, we'll introduce you to the right talent for your project. Average time to match is under 48 hours.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Onboard the Talent</h3>
                                    <p>Get to work with your new team members to build the next unicorn.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowitsWork} alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;